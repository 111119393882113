<script setup>
import { onMounted, ref } from 'vue';
import LandingHeader from './components/LandingHeader.vue'
import LandingFooter from './components/LandingFooter.vue'
import { homeAnimation } from "./libs/gsap";

const pageLoaded =ref(false)

onMounted(() => {
// //   homeAnimation();
// // setTimeout(() => {
//   // homeAnimation();
// // }, 1000);
window.onload = () => {
  document.querySelector('body').classList.add("loaded")  
  // pageLoaded.value=true
    // homeAnimation();
  };
  // GLightbox({
  //   selector: ".video__play"
  // });

});
</script>
<template>
  <div class="moather-body">
    <div  class="loader-wrapper">
      <div class="loader__content">
        <img src="./assets/images/logo.png" alt="">
        <h1>منصة <span>مؤثر</span></h1>
        <p>المنصة الأولى والأكبر للمؤثرين العرب</p>
      </div>
      <div class="loader">

        <loader />
      </div>

      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <landing-header />
    <div class="moather-page-wrapper">
      <router-view />
    </div>
    <landing-footer />

  </div>
</template>
<style lang="scss"></style>
