<template>
  <footer>
      <div class="container">
        <div class="row align-start flex-wrap">
          <div class="col col-5-md col-12">
            <div class="footer-right">
              <img src="../assets/images/logo.png" alt="moather logo" />
              <p>
                المنصة الأولى للمؤثرين في العالم العربي
              </p>
              <div class="row align-center no-margin ">
                <a v-if="contactStore.contact?.tiktok" :href="`https://www.tiktok.com/${contactStore.contact?.tiktok}`" target="_blank" class="btn btn__icon">
                  <tiktok  />
                </a>
                <a v-if="contactStore.contact?.x_platform" :href="`https://x.com/${contactStore.contact?.x_platform}`" target="_blank" class="btn btn__icon">
                  <x-platform  />
                </a>
                <a v-if="contactStore.contact?.instagram" :href="`https://www.instagram.com/${contactStore.contact?.instagram}`" target="_blank" class="btn btn__icon">
                  <instagram />
                </a>
                <a v-if="contactStore.contact?.linkedin" :href="`https://www.linkedin.com/company/${contactStore.contact?.linkedin}`" target="_blank" class="btn btn__icon">
                  <linkedin  />
                </a>
              </div>
            </div>
          </div>
          <div class="col col-2-md col-12">
            <div class="footer__menu">
              <h6>روابط تهمك</h6>
              <ul>
                <li><router-link :to="{ name: 'home' }">الرئيسية</router-link></li>
                <li><router-link :to="{ name: 'contact' }">تواصل معنا</router-link></li>
                <li><router-link :to="{ name: 'campaigns' }">الحملات</router-link></li>
                <li><router-link :to="{ name: 'about' }">من نحن</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col col-2-md col-12">
            <div class="footer__menu">
              <h6>عن مؤثر</h6>
              <ul>
                <li> <router-link :to="{ name: 'blogs' }"  > المدونة</router-link></li>
                <li><router-link :to="{ name: 'clients' }" >عملائنا</router-link></li>
                <li><router-link  :to="{ name: 'policy' }" >سياسة الخصوصية</router-link></li>
                <!-- <li><router-link :to="{ name: 'rules' }" >شروط الاستخدام</router-link></li> -->
              </ul>
            </div>
          </div>
          <div class="col col-3-md col-12">
            <div class="footer__menu">
              <h6>عنوان مؤثر</h6>
              <p>{{contactStore.contact?.location}}</p>
              <div class="footer__map">
                <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6%D8%8C%20%D8%B4%D8%A7%D8%B1%D8%B9%20%D8%A7%D9%84%D8%B9%D9%84%D9%8A%D8%A7%D8%A1%D8%8C%20%D8%A7%D9%84%D9%85%D9%84%D9%83%20%D9%81%D9%87%D8%AF%2013371+(%D9%85%D8%A4%D8%AB%D8%B1%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <p class="copyright">جميع الحقوق محفوظة ل Moather &copy; 2024</p>
      </div>
    </footer>
</template>

<script setup> 
import { onMounted  } from "vue";
import { useContactStore } from "@/stores/contact";
const contactStore = useContactStore();

onMounted(() => {
  contactStore._getContactInfo();
});
</script>