import axios from "axios";
import router from "../router";
// import { useAppsStore } from "@/stores/app";

const baseURL = import.meta.env.VITE_API_URL;
let i = 1;
const axiosInstance = (app) => {
  let notify = app.config.globalProperties.$wameedNotify;
  app.config.globalProperties.$http = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json;  charset=UTF-8",
    },
  });
  let $http = app.config.globalProperties.$http; 
  $http.interceptors.response.use(
    (response) => {
      // const appsStore = useAppsStore();

      // appsStore.setErrors({});
      return response;
    },
    (error) => {
      switch (error.response.status) {
       
        case 400:
          notify({
            title:  error.response.data?.error[0]?.message ?? "400",
            type: "fail",
            api:true
          });
          break;
        case 403:
        case 404:
          router.push({
            name: "not-found",
            params: {
              lang: router.currentRoute.value.params.lang,
            },
          });
          break;
        case 422:
          const appsStore = useAppsStore();

          appsStore.setErrors(error.response.data.data);

          notify({
            title: error.response.data.message,
            type: "fail",
            api:true
          });
          break;
        default:
          notify({
            title: "500",
            type: "fail",
            api:true
          });
      }
      //handel errors here
      return Promise.reject(error);
    }
  );
  return app.config.globalProperties.$http;
};
export default axiosInstance;
