import arrowLeftMain from "./icons/arrow-left-main.svg?component";
import arrowLeftPink from "./icons/arrow-left-pink.svg?component";
import arrowLeftWhite from "./icons/arrow-left-white.svg?component";
import arrowRightPink from "./icons/arrow-right-pink.svg?component";
import arrowRightWhite from "./icons/arrow-right-white.svg?component";

import CastIconWhite from "./icons/cast-icon-white.svg?component";
import CastIcon from "./icons/cast-icon.svg?component";
import comment from "./icons/comment.svg?component";
import dote_1 from "./icons/dote_1.svg?component";
import dote_2 from "./icons/dote_2.svg?component";
import dote_3 from "./icons/dote_3.svg?component";
import dote_4 from "./icons/dote_4.svg?component";
import facebook from "./icons/facebook.svg?component";

import fiRrArrowSmallLeft1 from "./icons/fi-rr-arrow-small-left-1.svg?component";
import fiRrArrowSmallLeftWhite from "./icons/fi-rr-arrow-small-left-white.svg?component";
import fiRrEnvelope from "./icons/fi-rr-envelope.svg?component";
import fiRrMarker from "./icons/fi-rr-marker.svg?component";
import instagram from "./icons/instagram.svg?component";

import layer1White from "./icons/layer1-white.svg?component";
import layer1 from "./icons/layer1.svg?component";
import linkedin from "./icons/linkedin.svg?component";

import loader from "./icons/loader.svg?component";

import menuWhite from "./icons/menu-white.svg?component";
import menu from "./icons/menu.svg?component";
import playButtonWhite from "./icons/play-button-white.svg?component";
import playButton from "./icons/play-button.svg?component";
import rightQuoteSign from "./icons/right-quote-sign.svg?component";
import telephone from "./icons/telephone.svg?component";


import xPlatform from "./icons/x-platform.svg?component";
import tiktok from "./icons/tiktok.svg?component";

import usersIconWhite from "./icons/users_icon-white.svg?component";
import usersIcon from "./icons/users_icon.svg?component";
import xMark from "./icons/x-mark.svg?component";


import logo from "../logo.svg?component";
import logoLight from "../logo-light.svg?component";

 
const svgComponents = (app) => {
  return app
    .component("logo", logo)
    .component("logo-light", logoLight)

     .component("arrow-left-main",arrowLeftMain )
     .component("arrow-left-pink",arrowLeftPink )
     .component("arrow-left-white",arrowLeftWhite )
     .component("arrow-right-pink",arrowRightPink )
     .component("arrow-right-white",arrowRightWhite )
     .component("cast-icon-white",CastIconWhite )//
     .component("cast-icon",CastIcon )//
     .component("comment",comment )
     .component("dote_1",dote_1 )//
     .component("dote_2",dote_2 )//
     .component("dote_3",dote_3 )
     .component("dote_4",dote_4 )//
     .component("facebook",facebook )
     .component("fiRrArrowSmallLeft1",fiRrArrowSmallLeft1 )
     .component("fiRrArrowSmallLeftWhite",fiRrArrowSmallLeftWhite )
     .component("fi-rr-envelope",fiRrEnvelope )
     .component("fi-rr-marker",fiRrMarker )
     .component("instagram",instagram )
     .component("layer1-white",layer1White )//
     .component("layer1",layer1 )//
     .component("linkedin",linkedin )//
     .component("loader",loader )//
     .component("menu-white",menuWhite )
     .component("menu-icon",menu )//
     .component("playButtonWhite",playButtonWhite )
     .component("play-button",playButton )//
     .component("right-quote-sign",rightQuoteSign )//
     .component("telephone",telephone )//
     .component("x-platform",xPlatform )//
     .component("tiktok",tiktok )//
     
     .component("users-icon-white",usersIconWhite )//
     .component("users-icon",usersIcon )//
     .component("x-mark",xMark )//

 


  ;
};
export default svgComponents;
