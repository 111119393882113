import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axiosInstance from "./libs/axios";
import svgComponents from "./assets/svg";

import "./assets/scss/main.scss";
import AOS from 'aos'
import 'aos/dist/aos.css'
import toastification from "./libs/toastification";
import wameedLoading from "./libs/wameedLoading";

const app = createApp(App);
toastification(app);
axiosInstance(app);
wameedLoading(app);

const pinia = createPinia();
pinia.use(({ store }) => {
    store.$http = markRaw(app.config.globalProperties.$http);
    store.$wameedNotify = markRaw(app.config.globalProperties.$wameedNotify);
    store.$wameedLoading = markRaw(app.config.globalProperties.$wameedLoading);

});

app.use(pinia).use(router);
app.use(AOS);
svgComponents(app);

app.mount("#app");
