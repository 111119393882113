import Toast, { useToast } from "vue-toastification";
import ToastificationContent from "@/components/Toastification.vue";

const toastification = (app) => {
  const toast = useToast();
  app.use(Toast, {
    hideProgressBar: true,
    closeOnClick: false,
    closeButton: false,
    icon: false,
    timeout: 13000,
    transition: "Vue-Toastification__fade",
  });
  return (app.config.globalProperties.$wameedNotify = (data) =>
    toast(
      {
        component: ToastificationContent,
        props: {
          title: data.title,
        },
      },
      {
        toastClassName: data.type + "-class",
        position: "bottom-left",
      }
    ));
};
export default toastification;



