const wameedLoading = (app) => {
  return (app.config.globalProperties.$wameedLoading = (id, start) => {
    if (id) {
      let elementId = document.getElementById(id);
      if (elementId) {
        let elementImg = elementId.getElementsByTagName("img");
        let elementCard = elementId.getElementsByClassName("wameed-card");
        if (elementId.tagName === "BUTTON")
          start
            ? elementId.classList.add("is-loading")
            : elementId.classList.remove("is-loading");
        else {
          if (start) {
            elementId.classList.add("is-loading");

            for (var imge = 0; imge < elementImg.length; imge++) {
              elementImg[imge].parentElement.classList.add("loading-img");
            }
            for (var card = 0; card < elementCard.length; card++) {
              elementCard[card].parentElement.classList.remove(
                "card-animation"
              );
            }
          } else {
            elementId.classList.remove("is-loading");

            for (var imge1 = 0; imge1 < elementImg.length; imge1++) {
              elementImg[imge1].parentElement.classList.remove("loading-img");
            }
            for (var card1 = 0; card1 < elementCard.length; card1++) {
              elementCard[card1].parentElement.classList.add("card-animation");
            }
          }
        }
      }
    }
  });
};
export default wameedLoading;
